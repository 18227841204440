<template>
  <div class="use">
    <div class="phone-head-public animate__animated animate__fadeIn">
      <img src="@/assets/img/banner2.png" alt="" />
    </div>
    <div class="cnt-public">
      <div class="title-public">
        <p>Application case</p>
        <h5>
          <span class="line-l"></span>
          <span class="center">应用案例</span>
          <span class="line-r"></span>
        </h5>
      </div>
       <div class="matter">
        <div class="wp">
          <div
            v-for="(item, index) in list"
            :key="index"
            @click="skip(item)"
          >
            <img :src="item.cover" alt="" />
            <div class="text">
              <h3>{{ item.title }}</h3>
              <p class="content-wp" v-html="item.content"></p>
            </div>
          </div>
        </div>
        <div class="block">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :current-page.sync="page"
            :page-size="pageSize"
            @current-change="pageChange"
            prev-text="上一页"
            next-text="下一页"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      pageSize: 6,
    };
  },
  methods: {
    gain() {
      this.axios({
        method: "get",
        url: "/wzn/application-case/page",
        params: {
          pageNo: this.page,
          pageSize: this.pageSize,
          // title: "home",
        },
      }).then((res) => {
        console.log("应用案例", res.data);
        this.list = res.data.data.list;
        // this.list = res.data.data.list.reverse();
        this.total = res.data.data.total;
      });
    },
    pageChange() {
      this.gain();
    },
    skip(item) {
      this.$router.push({
        path: "/Puse/info",
        query: { title: item.title, cnt: item.content, imgUrl: item.cover },
      });
    },
  },
  created() {
    this.gain();
  },
};
</script>
    
<style scoped>
.matter {
  padding: 0 5%;
}
.wp > div {
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
}
.wp > div > img {
  width: 100%;
  height: 300px;
}

.wp .text {
  padding: 24px;
  background: #fff;
}
.wp .text > h3 {
  font-size: 18px;
}
.content-wp {
  height: 25.2px;
  overflow: hidden;
}
.block {
  text-align: center;
}
</style>
<style>
.content-wp > p {
  margin-top: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #888;
  font-size: 14px;
}
.use .el-pagination button,
.use .el-pagination button span {
  min-width: 64px !important;
  height: 40px !important;
  line-height: 40px !important;
  background: #edeff4;
}
.use .el-pagination.is-background .el-pager li {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #edeff4;
}
</style>